<!-- @Author: Yu_Bo -->
<template>
	<div class='to_examine zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="940px" :close-on-click-modal='false' @close='cancelBtn'>
      <div slot="title">
        <div class="name">模型训练详情</div>
      </div>
      <div class="inv_main scroll_bar_x" style="padding-bottom: 20px;" v-loading='loading'>
        <!-- 客户信息 -->
        <div class="user_info" style="margin-top: 0px;">
          <div class="info_title">客户信息</div>
          <div class="info_txt">
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">客户姓名：</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{nickname}}</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">联系电话：</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{phone}}</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">客户账号：</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{member_name}}</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">订单金额：</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{order_money}}元</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">所属项目：</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{project_name}}</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">客户等级：</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{member_leve}}</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">视频时长：</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{video_duration}}分钟</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">部署平台：</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{platform_name}}</div>
              </div>
            </div>
            <div class="txt" v-if="order_img">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">订单图片：</span>
              </div>
              <div class="t_right">
                <div class="r_upimg">
                  <el-image style="width: 100%; height: 100%" :src="order_img" fit="scale-down"></el-image>
                </div>
              </div>
            </div>
            <div class="info_two">
              <div class="info_txt" v-if="video_audio">
                <div class="t_left">客户音频：</div>
                <div class="t_mp">
                  {{video_audio_name}}
                  <audio ref="playAudio" :src="video_audio" @pause="pauseBtn" controls hidden></audio>
                  <img v-if="playType==1" @click="playBtn" src="@/assets/images/icon/play_icon1.png" alt="">
                  <img v-if="playType==2" @click="pauseBtn" src="@/assets/images/icon/play_icon2.png" alt="">
                </div>
              </div>
              <div class="info_txt">
                <div class="t_left">创建时间：</div>
                <div class="t_right">{{create_time}}</div>
              </div>
              <div class="info_txt" v-if="audit_time">
                <div class="t_left">审核时间：</div>
                <div class="t_right">{{audit_time}}</div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="user_info">
          <div class="info_title">模特信息</div>
          <div class="info_txt">
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">模特名称</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{name}}</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">模型有效期</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{expiry_date}}</div>
              </div>
            </div>
            <div class="txt" style="width: 100%;">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">模特归属</span>
              </div>
              <div class="t_right">
                <div class="r_txt" v-if="model_list_id">{{'关联模特-'+model_name}}</div>
                <div class="r_txt" v-else>新建模特</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">训练视频</span>
              </div>
              <div class="t_right">
                <div class="img_url">
                  <video :src="training_video" controls></video>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left" style="padding-left: 13px;">
                <span class="left_d">*</span>
                <span class="left_t">形象授权</span>
              </div>
              <div class="t_right">
                <div class="img_url" v-if="file_type==1">
                  <video :src="grant_file" controls></video>
                </div>
                <div class="img_url" v-else>
                  <el-image style="width: 100%; height: 100%" :src="grant_file" fit="scale-down"></el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="user_info">
          <div class="info_title">其他信息</div>
          <div class="info_txt">
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">开票抬头</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{invoice_title}}</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">纳税人识别号</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{invoice_identifier}}</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">开户银行</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{bankname}}</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">银行账户</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{bank_account}}</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">公司地址</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{address}}</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">联系电话</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{mobile}}</div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">电子邮箱</span>
              </div>
              <div class="t_right">
                <div class="r_txt">{{email}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 失败原因 -->
        <div class="main_txt" v-if="remark">
          <div class="txt_left">拒绝原因：</div>
          <div class="txt_right">{{remark}}</div>
        </div>
      </div>
    </el-dialog>
	</div>
</template>

<script>
  import {
      mixinIndex
  } from "@/mixin/index"
	export default {
    mixins: [mixinIndex],
		components: {},
		props: {
      platform_type:{
        type:Array,
        defaul:()=>[]
      }
    },
		data() {
			return{
        dialogVisible:false,
        loading:false,
        btnLoading:false,
        //
        nickname:'',
        phone:'',
        member_name:"",
        order_money:'',
        project_name:'',
        member_leve:'',
        video_duration:'',
        platform_ids:'',
        platform_name:'',
        order_img:'',
        video_audio:'',
        video_audio_name:'',
        playType:1,//未播放=1
        create_time:'',
        audit_time:'',
        name:'',
        expiry_date:'',
        model_list_id:'',
        model_name:'',
        training_video:'',
        grant_file:'',
        file_type:1,
        remark:"",
        //
        invoice_title:"",
        invoice_identifier:"",
        bankname:"",
        bank_account:"",
        address:"",
        mobile:"",
        email:"",
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(val) {
        this.dialogVisible = true
        this.infoDetails(val)
      },
      // 取消
      cancelBtn() {
        this.dialogVisible = false
      },
      playBtn(){
        this.playType=2
        this.$refs.playAudio.play();//播放
      },
      pauseBtn(){
        this.playType=1
        this.$refs.playAudio.pause();//暂停
      },
      // 详情
      infoDetails(val){
        var that = this
        that.loading=true
        that.$trainApi.modelListDetails(val).then(res => {
          that.loading=false
          if (res.code == 1000) {
            that.nickname=res.result.nickname
            that.phone=res.result.phone
            that.member_name=res.result.member_name
            that.order_money=res.result.order_money
            that.project_name=res.result.project_name
            that.member_leve=res.result.member_leve
            that.video_duration=res.result.video_duration
            that.platform_ids=res.result.platform_ids
            if(that.platform_ids){
              var str = that.platform_ids.split(',')
              that.platformBtn(str)
            }
            that.order_img=res.result.order_img
            that.video_audio=res.result.video_audio
            if(that.video_audio){
              var arr = that.video_audio.split('/')
              that.video_audio_name=arr[arr.length-1]
            }else{
              that.video_audio=''
            }
            that.create_time=res.result.create_time
            that.audit_time=res.result.audit_time
            that.name=res.result.name
            that.expiry_date=res.result.expiry_date
            that.model_list_id=res.result.model_list_id
            that.model_name=res.result.model_name
            that.training_video=res.result.training_video
            that.grant_file=res.result.grant_file
            if(that.grant_file.indexOf("mp4") != -1 || that.grant_file.indexOf("mov") != -1){
              that.file_type=1
            }else{
              that.file_type=2
            }
            that.remark=res.result.remark
            if(res.result.invoice){
              that.invoice_title=res.result.invoice.invoice_title
              that.invoice_identifier=res.result.invoice.invoice_identifier
              that.bankname=res.result.invoice.bankname
              that.bank_account=res.result.invoice.bank_account
              that.address=res.result.invoice.address
              that.mobile=res.result.invoice.mobile
              that.email=res.result.invoice.email
            }
          } else {
            this.$errMsg(res.message)
          }
        })
      },
      platformBtn(str){
        var that = this
        var arr = []
        for(var i=0;i<str.length;i++){
          for(var n=0;n<that.platform_type.length;n++){
            if(str[i]==that.platform_type[n].value){
              arr.push(that.platform_type[n].label)
            }
          }
        }
        that.platform_name=arr.join(',')
      },
    },
	}
</script>

<style lang='scss' scoped>
	.to_examine{
    ::v-deep .el-dialog__body{
      padding: 0;
    }
    .inv_main {
      width: 100%;
      padding: 20px;
      height: 500px;
      overflow-y: auto;
      .user_info{
        width: 100%;
        margin-top: 20px;
        padding: 0 20px;
        background: #F7F7F7;
        .info_title{
          width: 100%;
          line-height: 50px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          border-bottom: 1px solid #E7E7E7;
          span{
            padding-left: 10px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #F2912E;
          }
        }
        .info_txt{
          width: 100%;
          padding-top: 5px;
          display: flex;
          flex-wrap: wrap;
          .txt{
            width: 45%;
            padding-top: 10px;
            display: flex;
            .t_left{
              width: 100px;
              height: 32px;
              display: flex;
              align-items: center;
              .left_d{
                display: none;
                width: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF2B2B;
              }
              .left_t{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              }
              img{
                display: block;
                width: 14px;
                height: 14px;
                margin-left: 5px;
                margin-top: 2px;
              }
            }
            .t_right{
              display: flex;
              .r_txt{
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
              }
              .r_input{
                width: 210px;
                .el-input{
                  width: 100%;
                }
                .el-select{
                  width: 100%;
                }
                ::v-deep .el-input-group__append{
                  padding: 0 10px;
                  color: #fff;
                  background: #2E4BF2;
                }
              }
              .r_checkbox{
                padding-left: 5px;
                display: flex;
                .el-checkbox{
                  padding-top: 7px;
                  margin-right: 10px;
                }
                .el-checkbox:last-child{
                  margin-right: 0;
                }
              }
              .r_upimg{
                width: 40px;
                height: 80px;
                margin-top: 10px;
                img{
                  display: block;
                  width: 100%;
                  height: 100%;
                }
              }
              .r_btn{
                cursor: pointer;
                line-height: 32px;
                margin-left: 20px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2E4BF2;
              }
              .img_url{
                margin-top: 7px;
                width: 226px;
                height: 131px;
                border-radius: 1px;
                border: 1px solid #DDDFE6;
                video{
                  display: block;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          .txt:nth-child(2n){
            width: 55%;
          }
          .info_two{
            width: 55%;
            height: 140px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            .info_txt{
              width: 100%;
              height: 40px;
              line-height: 40px;
              display: flex;
              .t_left{
                width: 100px;
              }
              .t_right{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
              }
              .t_mp{
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2E4BF2;
                img{
                  cursor: pointer;
                  display: block;
                  width: 15px;
                  height: 15px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
        .info_con{
          width: 100%;
          padding-top: 20px;
          display: flex;
          justify-content: space-between;
          .con_left{
            width: 420px;
            .left_txt{
              width: 100%;
              padding-bottom: 20px;
              display: flex;
              .name{
                width: 100px;
                line-height: 32px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              }
              .t_con{
                width: 320px;
                padding-right: 40px;
                .con_img{
                  cursor: pointer;
                  width: 88px;
                  height: 88px;
                  border-radius: 50%;
                  img{
                    display: block;
                    width: 100%;
                    height: 100%;
                  }
                  .img_header{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    span{
                      position: absolute;
                      top: 0;
                      left: 0;
                      display: block;
                      width: 100%;
                      line-height: 88px;
                      text-align: center;
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #FFFFFF;
                    }
                  }
                }
              }
            }
            .left_txt:nth-child(2){
              border-bottom: 1px solid #E7E7E7;
            }
            /*  */
            .left_zip{
              width: 100%;
              padding-top: 20px;
              padding-right: 50px;
              .zip_list{
                width: 100%;
                .list{
                  width: 100%;
                  margin-bottom: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .list_txt{
                    width: 310px;
                    height: 48px;
                    padding: 0 15px;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .left{
                      width: 260px;
                      display: flex;
                      align-items: center;
                      img{
                        display: block;
                        width: 15px;
                        height: 15px;
                      }
                      span{
                        display: block;
                        padding-left: 10px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                      }
                    }
                    .right .el-icon-success{
                      color: #FF7432;
                    }
                  }
                  .list_btn{
                    cursor: pointer;
                    width: 48px;
                    height: 48px;
                    background: rgba(46,75,242,0.08);
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .el-icon-delete{
                      font-size: 16px;
                      color: #2B4AFE;
                    }
                  }
                }
              }
              .zip_tip{
                width: 100%;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF495E;
              }
              .zip_btn{
                padding-top: 20px;
              }
              .zip_checkbox{
                padding-top: 30px;
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                span{
                  cursor: pointer;
                  color: #2E4BF2;
                }
                .el-checkbox{
                  margin-right: 5px;
                }
              }
            }
          }
          .con_right{
            width: 425px;
            padding: 20px;
            background: #FFFFFF;
            border-radius: 4px;
            .t_title{
              width: 100%;
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              .el-icon-success{
                font-size: 16px;
                color: #FCBD93;
                margin-right: 5px;
                margin-top: 2px;
              }
              .el-icon-warning{
                font-size: 16px;
                color: #FC9393;
                margin-right: 5px;
                margin-top: 2px;
              }
            }
            .right_txt{
              width: 100%;
              padding: 20px 0 10px;
              span{
                padding-bottom: 10px;
                display: block;
                line-height: 20px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9097A5;
              }
            }
            .note_list{
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              .list{
                padding-top: 20px;
                padding-right: 30px;
                img{
                  display: block;
                  width: 60px;
                  height: 38px;
                  margin: 0 auto 12px;
                }
                span{
                  font-size: 14px;
                  text-align: center;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                }
              }
              .list:nth-child(3n){
                padding-right: 0;
              }
            }
          }
        }
      }
    }
    .main_txt{
      width: 100%;
      padding: 0 20px;
      margin-top: 20px;
      height: 60px;
      background: #F7F7F7;
      display: flex;
      align-items: center;
      .txt_left{
        width: 100px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      .txt_right{
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
	}
</style>
