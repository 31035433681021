<!-- @Author: Yu_Bo -->
<template>
  <div class='add_model zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="940px" :close-on-click-modal='false' @close='cancelBtn'>
      <div slot="title">
        <div class="name">新增模型训练</div>
      </div>
      <div class="inv_main scroll_bar_x">
        <!-- 客户信息 -->
        <div class="user_info" style="margin-top: 0px;">
          <div class="info_title">客户信息</div>
          <div class="info_txt">
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">客户姓名</span>
                <el-tooltip placement="top">
                  <div slot="content" style="line-height: 20px;">个人客户填写客户名称，企<br />业用户填写公司名称</div>
                  <img src="@/assets/images/icon/wen_icon.png" alt="">
                </el-tooltip>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="name" placeholder="请输入客户姓名/公司名称"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">联系电话</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="phone" maxlength="11" placeholder="请输入联系电话"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">客户账号</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-select v-model="account" size="small" filterable remote reserve-keyword placeholder="请选择客户的平台账号"
                    :remote-method="remoteMethod" :loading="selectLoading">
                    <el-option v-for="item in accountList" :key="item.id" :label="item.nickname" :value="item.id">
                      <span style="float: left">{{ item.nickname }}</span>
                      <span style="float: right; color: #2E4BF2; font-size: 14px">训练次数：{{ item.major_cust || 0 }}</span>
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">订单金额</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="money" placeholder="请输入订单金额">
                    <template slot="append"><span>元</span></template>
                  </el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">所属项目</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="projectName" placeholder="请输入所属项目"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">客户等级</span>
                <el-tooltip placement="top">
                  <div slot="content" style="line-height: 20px;">根据客户重要度选择，数值越大<br />等级越高</div>
                  <img src="@/assets/images/icon/wen_icon.png" alt="">
                </el-tooltip>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-select size="small" v-model="level" placeholder="请选择客户等级">
                    <el-option v-for="item in levelList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">视频时长</span>
                <el-tooltip placement="top">
                  <div slot="content" style="line-height: 20px;">此字段仅作记录使用，不会自动充<br />值，每日管理员会定时查看列表帮<br />忙充值时长</div>
                  <img src="@/assets/images/icon/wen_icon.png" alt="">
                </el-tooltip>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-select size="small" v-model="duration" placeholder="请选择需要充值的音频时长">
                    <el-option v-for="item in durationList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">部署平台</span>
              </div>
              <div class="t_right">
                <div class="r_checkbox">
                  <el-checkbox v-model="item.show" v-for="(item,index) in platform_list" :key="index"
                    @change='changeBtn(index,$event)'>{{item.label}}</el-checkbox>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">订单图片</span>
                <el-tooltip placement="top">
                  <div slot="content" style="line-height: 20px;">如无订单图片，则需要走钉钉审核并<br />上传钉钉审核截图</div>
                  <img src="@/assets/images/icon/wen_icon.png" alt="">
                </el-tooltip>
              </div>
              <div class="t_right">
                <div class="r_upimg" v-loading='imgLoading'>
                  <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="uploadimg">
                    <el-image v-if="imageUrl" style="width: 90px; height: 90px" :src="imageUrl" fit="scale-down"></el-image>
                    <img v-else :src="require('@/assets/images/img/up_img.png')" alt="">
                  </el-upload>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">客户音频</span>
              </div>
              <div class="t_right">
                <div class="r_upAudio" v-if="audioUrl">
                  <div class="audio_txt">
                    {{audioUrl_name}}
                    <img v-if="play_type==1" @click="playBtn" src="@/assets/images/icon/play_icon1.png" alt="">
                    <img v-if="play_type==2" @click="pauseBtn" src="@/assets/images/icon/play_icon2.png" alt="">
                  </div>
                  <audio ref="playAudio" :src="audioUrl" @pause="pauseBtn" controls hidden></audio>
                  <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="uploadaudio">
                    <div class="btn_txt" v-loading='audioLoading'>重新上传</div>
                  </el-upload>
                </div>
                <div class="r_upAudio" v-else>
                  <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="uploadaudio">
                    <div class="btn_txt" v-loading='audioLoading'>点击上传音频</div>
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="user_info user_info_two">
          <div class="info_title">模特信息</div>
          <div class="info_txt">
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">模特名称</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="model_name" placeholder="请输入模特名称"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">模特有效期</span>
              </div>
              <div class="t_right">
                <div class="r_input" v-if="dayShow">
                  <el-input size="small" v-model="dayTime" placeholder="请输入模特有效期">
                    <template slot="append"><span>天</span></template>
                  </el-input>
                </div>
                <div class="r_input" v-else>
                  <el-select size="small" v-model="dayNum" placeholder="请选择模特有效期">
                    <el-option v-for="item in dayList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="r_btn" @click="dayShow=!dayShow">{{dayShow?'取消':'自定义'}}</div>
              </div>
            </div>
            <!--  -->
            <div class="txt" style="width: 100% !important;">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">模特归属</span>
              </div>
              <div class="t_right_raio">
                <el-radio v-model="model_radio" :label="1">
                  新建模特
                  <el-tooltip placement="top">
                    <div slot="content" style="line-height: 20px;">将在模特列表中新增一位模特信息</div>
                    <img src="@/assets/images/icon/wen_icon.png" alt="">
                  </el-tooltip>
                </el-radio>
                <el-radio v-model="model_radio" :label="2">
                  关联模特
                  <el-tooltip placement="top">
                    <div slot="content" style="line-height: 20px;">将关联已存在模特，为模特分身场景</div>
                    <img src="@/assets/images/icon/wen_icon.png" alt="">
                  </el-tooltip>
                </el-radio>
                <div class="r_input" v-if='model_radio==2'>
                  <el-select size="small" v-model="modelValue" placeholder="请选择模特">
                    <el-option v-for="item in modelList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">训练视频</span>
              </div>
              <div class="t_right">
                <div class="exam_btn" v-loading='videoLoading'>
                  <div class="btn_up" v-if="video_url">
                    <div class="video_info">
                      <video :src="video_url" controls></video>
                    </div>
                  </div>
                  <div class="btn_up" v-else>
                    <el-upload action="#" :show-file-list="false" :http-request="uploadvideo">
                      <div class="video_btn">
                        <img src="@/assets/images/icon/upload.png" alt="">
                        <span>上传视频</span>
                      </div>
                    </el-upload>
                  </div>
                  <div class="btn_txt">
                    <div class="txt_btn_img">
                      <el-upload action="#" :show-file-list="false" :http-request="uploadvideo">
                        <img src="@/assets/images/icon/upload.png" alt="">
                        <span>重新上传</span>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">拍摄示例</span>
              </div>
              <div class="t_right">
                <div class="exam_img">
                  <video controls="controls" class="video_cover"
                    poster="http://yc-digital.oss-cn-shenzhen.aliyuncs.com/image/20230619/ynxXUSqVEwes-Uqlwd.png"
                    src="https://yc-digital.oss-cn-shenzhen.aliyuncs.com/video/20230612/iMAXYPfTGLwv-hJCdv.mp4"></video>
                </div>
              </div>
            </div>
          </div>
          <!--提示  -->
          <div class="info_con">
            <div class="con_left">
              <div class="con_title">
                <i class="el-icon-success"></i>
                上传提示
              </div>
              <div class="left_span">
                <span>1. 上传本地视频，人工审核精选过滤后为您训练输出更精美的数字人形象；</span>
                <span>2. 训练视频仅支持时长大于1min，小于5min，尺寸为9:16/16:9，分辨率大于720p小于4k的MP4/MOV格式视频，2k以上会自动压缩为1080p；</span>
                <span>3. 视频要求为正面、五官清晰无遮挡，不要歪头、侧头；</span>
                <span>4. 请保持视频中人脸比例适中，涵盖肩膀区域，头部比例不要过大或者过小；</span>
                <span>5. 上传视频将用于数字人定制，请慎重选择视频上传；</span>
              </div>
            </div>
            <div class="con_right">
              <div class="con_title">
                <i class="el-icon-error"></i>
                不佳示范
              </div>
              <div class="right_list">
                <div class="poor" v-for="(item,index) in poorList" :key="index">
                  <img :src="item.img" alt="">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="info_txt">
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">形象授权</span>
              </div>
              <div class="t_right">
                <div class="exam_btn" v-loading='fileLoading'>
                  <div class="btn_up" v-if="img_url">
                    <div class="video_info" v-if="img_type=='video'">
                      <video :src="img_url" controls></video>
                    </div>
                    <el-image v-else style="width: 100%; height: 100%" :src="img_url" fit="scale-down"></el-image>
                  </div>
                  <div class="btn_up" v-else>
                    <el-upload action="#" :show-file-list="false" :http-request="uploadxing">
                      <div class="video_btn">
                        <img src="@/assets/images/icon/upload.png" alt="">
                        <span>上传授权视频或授权书图片</span>
                      </div>
                    </el-upload>
                  </div>
                  <div class="btn_txt">
                    <div class="txt_btn_img" style="margin-bottom: 20px;">
                      <span>下载授权书</span>
                    </div>
                    <div class="txt_btn_img">
                      <el-upload action="#" :show-file-list="false" :http-request="uploadxing">
                        <img src="@/assets/images/icon/upload.png" alt="">
                        <span>重新上传</span>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d">*</span>
                <span class="left_t">授权示例</span>
              </div>
              <div class="t_right">
                <div class="exam_img">
                  <video controls="controls" class="video_cover"
                    poster="http://yc-digital.oss-cn-shenzhen.aliyuncs.com/image/20230619/ynxXUSqVEwes-Uqlwd.png"
                    src="https://yc-digital.oss-cn-shenzhen.aliyuncs.com/video/20230612/iMAXYPfTGLwv-hJCdv.mp4"></video>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="user_info">
          <div class="info_title">其他信息 <span>*开票信息仅提供云川科技电子普票</span></div>
          <div class="info_txt">
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">开票抬头</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_top" placeholder="请输入开票抬头"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">纳税人识别号</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_num" placeholder="请输入纳税人识别号"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">开户银行</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_bank" placeholder="请输入开户银行"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">银行账户</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_acc" placeholder="请输入银行账户"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">公司地址</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_address" placeholder="请输入公司地址"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">联系电话</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_phone" placeholder="请输入联系电话"></el-input>
                </div>
              </div>
            </div>
            <div class="txt">
              <div class="t_left">
                <span class="left_d"></span>
                <span class="left_t">电子邮箱</span>
              </div>
              <div class="t_right">
                <div class="r_input">
                  <el-input size="small" v-model="other_email" placeholder="请输入电子邮箱"></el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" style="padding-top: 20px;">
        <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
        <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">提
          交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    mixinIndex
  } from "@/mixin/index"
  export default {
    mixins: [mixinIndex],
    components: {},
    props: {
      levelList: {
        type: Array,
        defaul: () => []
      },
      dayList: {
        type: Array,
        defaul: () => []
      },
      platform_type: {
        type: Array,
        defaul: () => []
      }
    },
    data() {
      return {
        dialogVisible: false,
        imgLoading: false,
        audioLoading: false,
        videoLoading: false,
        fileLoading: false,
        btnLoading: false,
        // 客户信息
        name: '',
        phone: '',
        //
        account: '',
        selectLoading: false,
        accountList: [],
        //
        money: '',
        projectName: '',
        //
        level: '',
        //
        duration: '',
        durationList: [{
            value: 0,
            label: '0分钟'
          },
          {
            value: 300,
            label: '300分钟'
          },
          {
            value: 500,
            label: '500分钟'
          },
          {
            value: 800,
            label: '800分钟'
          },
          {
            value: 1000,
            label: '1000分钟'
          },
        ],
        //
        platform_id: [],
        platform_list: [],
        //
        imageUrl: '',
        imageUrl_short_url: '',
        //
        audioUrl: '',
        audioUrl_short_url: '',
        audioUrl_name: '',
        play_type: 1, //1=未播放
        // 模特信息
        model_name: '',
        dayTime: '',
        dayNum: '',
        model_radio:1,
        modelValue:'',
        modelList:[
          {id:1,name:'1212'},
          {id:2,name:'112212'},
        ],
        //
        dayShow: false,
        video_url: '',
        video_url_short_url: '',
        img_url: '',
        img_url_short_url: '',
        img_type: 'video',
        // 其他信息
        other_top: '',
        other_num: '',
        other_bank: '',
        other_acc: '',
        other_address: '',
        other_phone: '',
        other_email: '',
      }
    },
    computed: {},
    watch: {
      account(val){
        if(val){
          this.getList()
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      // 获取模特列表
      getList(){
        var that = this
        var params = {
          member_id: that.account,
        }
        that.$trainApi.modelMlist(params).then(res => {
          if (res.code == 1000) {
            that.modelValue=''
            that.modelList = res.result
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 打开弹框
      openDialogBtn() {
        this.platform_list = this.platform_type
        this.dialogVisible = true
      },
      // 取消
      cancelBtn() {
        this.name = ''
        this.phone = ''
        //
        this.account = ''
        this.accountList = []
        //
        this.money = ''
        this.projectName = ''
        //
        this.level = ''
        //
        this.duration = ''
        //
        this.platform_id = []
        //
        this.imageUrl = ''
        this.imageUrl_short_url = ''
        //
        this.audioUrl = ''
        // 模特信息
        this.model_name = ''
        this.dayTime = ''
        this.dayNum = ''
        //
        this.dayShow = false
        this.video_url = ''
        this.video_url_short_url = ''
        this.img_url = ''
        this.img_url_short_url = ''
        this.img_type = 'video'
        // 其他信息
        this.other_top = ''
        this.other_num = ''
        this.other_bank = ''
        this.other_acc = ''
        this.other_address = ''
        this.other_phone = ''
        this.other_email = ''
        this.dialogVisible = false
      },
      //
      phoneBtn(val) {
        var that = this
        that.selectLoading = true
        var params = {
          mobile: val,
        }
        that.$trainApi.selectMember(params).then(res => {
          that.selectLoading = false
          if (res.code == 1000) {
            that.accountList = res.result
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      remoteMethod(query) {
        if (query !== '' && query.length==11) {
          setTimeout(() => {
            this.phoneBtn(query)
          }, 200);
        } else {
          this.accountList = []
        }
      },
      //
      changeBtn(index, val) {
        this.platform_id = []
        this.platform_list[index].show = val
        this.platform_list.forEach(item => {
          if (item.show) {
            this.platform_id.push(item.value)
          }
        })
        this.$forceUpdate()
      },
      // 上传订单图片
      uploadimg(item) {
        var self = this
        var is_img = ['image/gif', 'image/png', 'image/jpg', 'image/jpeg']
        // 限制图片格式
        const isJPG = is_img.includes(item.file.type)
        // 限制图片大小
        const isLt2M = item.file.size / 1024 / 1024 <= 3;
        if (!isJPG) {
          self.$errMsg('上传图片只能是 JPG ,JPEG, PNG 格式!');
        }
        if (!isLt2M) {
          self.$errMsg('上传图片大小不能超过 2MB!');
        }
        if (isJPG && isLt2M) {
          self.imgLoading = true
          let newFile = new FormData()
          newFile.append('type', 1); // type 图片大小限制 1：10M以内；2：15M；3：30M；4：3M
          newFile.append('file', item.file);
          self.$publicApi.uploadFile(newFile).then(res => {
            self.imgLoading = false
            if (res.code == 1000) {
              self.imageUrl = res.result.url
              self.imageUrl_short_url = res.result.short_url
            } else {
              self.$errMsg(res.message)
            }
          })
        }
      },
      // 上传音频
      uploadaudio(item) {
        var self = this
        console.log(item)
        var is_video = ['audio/mp3', 'audio/wav']
        // 限制格式
        const isUrl = is_video.includes(item.file.type)
        if (!isUrl) {
          self.$errMsg('上传音频只能是 mp3 ,wav 格式!');
        }
        if (isUrl) {
          self.audioLoading = true
          let newFile = new FormData()
          newFile.append('file', item.file);
          self.$publicApi.uploadFile(newFile).then(res => {
            self.audioLoading = false
            if (res.code == 1000) {
              self.audioUrl = res.result.url
              self.audioUrl_short_url = res.result.short_url
              var arr = self.audioUrl_short_url.split('/')
              self.audioUrl_name = arr[arr.length - 1]
            } else {
              self.$errMsg(res.message)
            }
          })
        }
      },
      // 上传训练视频
      uploadvideo(item) {
        var self = this
        var is_video = ['video/mp4', 'video/mov']
        // 限制格式
        const isUrl = is_video.includes(item.file.type)
        if (!isUrl) {
          self.$errMsg('上传视频只能是 mp4 ,mov 格式!');
        }
        if (isUrl) {
          self.videoLoading = true
          let newFile = new FormData()
          newFile.append('file', item.file);
          self.$trainApi.uploadVideo(newFile).then(res => {
            self.videoLoading = false
            if (res.code == 1000) {
              self.video_url = res.result.url
              self.video_url_short_url = res.result.short_url
            } else {
              self.$errMsg(res.message)
            }
          })
        }
      },
      // 上传形象授权
      uploadxing(item) {
        // console.log(item)
        var self = this
        if (item.file.type == 'video/mp4' || item.file.type == 'video/mov') {
          self.img_type = 'video'
        } else {
          self.img_type = 'image'
        }
        var is_video = []
        if (self.img_type == 'video') {
          is_video = ['video/mp4', 'video/mov']
        } else {
          is_video = ['image/gif', 'image/png', 'image/jpg', 'image/jpeg']
        }
        // console.log(is_video)
        // 限制格式
        const isUrl = is_video.includes(item.file.type)
        if (!isUrl) {
          self.$errMsg('上传文件格式不正确!');
        }
        if (isUrl) {
          self.fileLoading = true
          let newFile = new FormData()
          newFile.append('file', item.file);
          newFile.append('type', 1);
          self.$trainApi.uploadVideo(newFile).then(res => {
            self.fileLoading = false
            if (res.code == 1000) {
              self.img_url = res.result.url
              self.img_url_short_url = res.result.short_url
            } else {
              self.$errMsg(res.message)
            }
          })
        }
      },
      playBtn() {
        this.play_type = 2
        this.$refs.playAudio.play(); //播放
      },
      pauseBtn() {
        this.play_type = 1
        this.$refs.playAudio.pause(); //暂停
      },
      // 协议
      agreementBtn() {
        const {
          href
        } = this.$router.resolve({
          path: "/textPage/" + 1,
        });
        window.open(href, "_blank")
      },
      // 提交
      affirmBtn() {
        var that = this
        if (that.name == '') {
          that.$warMsg('请输入客户姓名/公司名称')
        } else if (that.phone == '') {
          that.$warMsg('请输入联系电话')
        } else if (that.account == '') {
          that.$warMsg('请选择客户的平台账号')
        } else if (that.money == '') {
          that.$warMsg('请输入订单金额')
        } else if (that.projectName == '') {
          that.$warMsg('请输入所属项目')
        } else if (that.duration == '') {
          that.$warMsg('请选择需要充值的视频时长')
        } else if (that.imageUrl == '') {
          that.$warMsg('请上传订单图片')
        } else if (that.model_name == '') {
          that.$warMsg('请输入模特名称')
        } else if (that.dayTime == '' && that.dayShow) {
          that.$warMsg('请输入模特有效期')
        } else if (that.dayNum == '' && !that.dayShow) {
          that.$warMsg('请选择模特有效期')
        } else if (that.model_radio == 2 && that.modelValue == '') {
          that.$warMsg('请选择关联模特模特')
        } else if (that.video_url == '') {
          that.$warMsg('请上传训练视频')
        } else if (that.img_url == '') {
          that.$warMsg('上传授权视频或授权书图片')
        } else {
          that.btnLoading = true
          var params = {
            nickname: that.name,
            phone: that.phone,
            order_money: that.money,
            member_id: that.account,
            project_name: that.projectName,
            member_leve: that.level,
            video_duration: that.duration,
            platform_ids: that.platform_id.join(','),
            order_img: that.imageUrl_short_url,
            video_audio: that.audioUrl_short_url,
            name: that.model_name,
            model_list_id:that.model_radio==1?0:that.modelValue,
            expiry_date: that.dayShow ? that.dayTime : that.dayNum,
            training_video: that.video_url_short_url,
            grant_file: that.img_url_short_url,
            invoice_title: that.other_top,
            invoice_identifier: that.other_num,
            bankname: that.other_bank,
            bank_account: that.other_acc,
            address: that.other_address,
            mobile: that.other_phone,
            email: that.other_email,
          }
          that.$trainApi.modelListPost(params).then(res => {
            that.btnLoading = false
            if (res.code == 1000) {
              that.$succMsg(res.message)
              that.cancelBtn()
              that.$emit('submit')
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      }
    },
  }
</script>
<style>
  .el-select-dropdown.is-multiple .el-select-dropdown__item {
    padding: 0 20px;
  }
</style>
<style lang='scss' scoped>
  .add_model {
    ::v-deep .el-dialog__body {
      padding: 0;
      padding-right: 10px;
    }

    .inv_main {
      width: 100%;
      padding: 20px 17px 0px 20px;
      height: 500px;
      overflow-y: auto;

      .user_info {
        width: 100%;
        margin-top: 20px;
        padding: 0 20px 20px;
        background: #F7F7F7;

        .info_title {
          width: 100%;
          line-height: 50px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          border-bottom: 1px solid #E7E7E7;

          span {
            padding-left: 10px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #F2912E;
          }
        }

        .info_txt {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .txt {
            width: 45%;
            padding-top: 20px;
            display: flex;

            .t_left {
              width: 100px;
              height: 32px;
              display: flex;
              align-items: center;

              .left_d {
                display: block;
                width: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FF2B2B;
              }

              .left_t {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              }

              img {
                display: block;
                width: 14px;
                height: 14px;
                margin-left: 5px;
                margin-top: 2px;
              }
            }
            .t_right_raio{
              display: flex;
              align-items: center;
              ::v-deep .el-radio{
                display: flex;
                .el-radio__label{
                  display: flex;
                  img{
                    display: block;
                    width: 14px;
                    height: 14px;
                    margin-left: 2px;
                    margin-top: 1px;
                  }
                }
              }
              .r_input {
                width: 150px;
              }
            }
            .t_right {
              display: flex;

              .r_input {
                width: 210px;

                .el-input {
                  width: 100%;
                }

                .el-select {
                  width: 100%;
                }

                ::v-deep .el-input-group__append {
                  padding: 0 10px;
                  color: #fff;
                  background: #2E4BF2;
                }
              }

              .r_checkbox {
                display: flex;

                .el-checkbox {
                  padding-top: 7px;
                  margin-right: 10px;
                }

                .el-checkbox:last-child {
                  margin-right: 0;
                }
              }

              .r_upimg {
                width: 90px;
                height: 90px;
              }

              .r_upAudio {
                width: 100%;
                line-height: 34px;

                .audio_txt {
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #2E4BF2;
                  display: flex;
                  align-items: center;

                  img {
                    display: block;
                    width: 15px;
                    height: 15px;
                    margin-left: 10px;
                  }
                }

                .btn_txt {
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #2E4BF2;
                }
              }

              .r_btn {
                cursor: pointer;
                line-height: 32px;
                margin-left: 20px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2E4BF2;
              }

              .exam_btn {
                position: relative;
                width: 255px;
                height: 148px;
                background: #F5F7FA;
                border-radius: 1px;
                border: 1px solid #DDDFE6;

                .btn_up {
                  width: 100%;
                  height: 100%;

                  ::v-deep .el-upload {
                    width: 255px;
                    height: 148px;
                  }

                  .video_info {
                    width: 100%;
                    height: 100%;

                    video {
                      display: block;
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                .video_btn {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;

                  img {
                    display: block;
                    width: 25px;
                    height: 25px;
                  }

                  span {
                    display: block;
                    padding-top: 5px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2E4BF2;
                  }
                }

                .btn_txt {
                  cursor: pointer;
                  position: absolute;
                  bottom: 0;
                  right: -100px;

                  .txt_btn_img {
                    width: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    ::v-deep .el-upload {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    img {
                      display: block;
                      width: 20px;
                      height: 20px;
                      margin-right: 5px;
                    }

                    span {
                      display: block;
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #2E4BF2;
                    }
                  }
                }
              }

              .exam_img {
                width: 255px;
                height: 148px;
                border-radius: 1px;
                border: 1px solid #DDDFE6;
                .video_cover{
                  display: block;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .txt:nth-child(2n) {
            width: 55%;
          }
        }

        .info_con {
          width: 100%;
          margin-top: 20px;
          padding: 20px;
          background: #FFFFFF;
          border-radius: 4px;
          border: 1px dashed #DDDFE6;
          display: flex;
          justify-content: space-between;

          .con_left {
            width: 500px;

            .con_title {
              width: 100%;
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;

              .el-icon-success {
                font-size: 18px;
                color: #FCBD93;
                margin-right: 5px;
              }
            }

            .left_span {
              width: 100%;

              span {
                display: block;
                width: 100%;
                padding-top: 10px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #9097A5;
                line-height: 20px;
              }
            }
          }

          .con_right {
            width: 260px;

            .con_title {
              width: 100%;
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;

              .el-icon-error {
                font-size: 18px;
                color: #FC9393;
                margin-right: 5px;
              }
            }

            .right_list {
              width: 100%;
              display: flex;
              flex-wrap: wrap;

              .poor {
                padding-top: 10px;
                padding-right: 30px;

                img {
                  display: block;
                  width: 60px;
                  height: 42px;
                  margin: auto;
                }

                span {
                  display: block;
                  width: 100%;
                  padding-top: 4px;
                  text-align: center;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                }
              }

              .poor:nth-child(3n) {
                padding-right: 0;
              }
            }
          }
        }
      }

      .user_info_two {
        .txt {
          width: 55% !important;
        }

        .txt:nth-child(2n) {
          width: 45% !important;
        }
      }
    }
  }
</style>
