<!-- @Author: Yu_Bo -->
<template>
	<div class='edit_info zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="400px" :close-on-click-modal='false' @close='cancelBtn'>
      <div slot="title">
        <div class="name">重新上传</div>
      </div>
      <div class="inv_main">
        <div class="exam_btn" v-loading='loading'>
          <div class="btn_up" v-if="video_url">
            <div class="video_info">
              <video :src="video_url" controls></video>
            </div>
          </div>
          <div class="btn_up" v-else>
            <el-upload action="#" :show-file-list="false" :http-request="uploadvideo">
              <div class="video_btn">
                <img src="@/assets/images/icon/upload.png" alt="">
                <span>上传视频</span>
              </div>
            </el-upload>
          </div>
          <div class="btn_txt">
            <div class="txt_btn_img">
              <el-upload action="#" :show-file-list="false" :http-request="uploadvideo">
                <img src="@/assets/images/icon/upload.png" alt="">
                <span>重新上传</span>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
        <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">确 定</el-button>
      </div>
    </el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        loading:false,
        btnLoading:false,
        id:'',
        video_url:'',
        video_url_short_url: '',
        //
        dialogVisible:false,
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(val) {
        this.dialogVisible = true
        this.id=val
      },
      // 取消
      cancelBtn() {
        this.id=''
        this.video_url=''
        this.video_url_short_url=''
        this.dialogVisible = false
      },
      // 上传训练视频
      uploadvideo(item) {
        var self = this
        var is_video = ['video/mp4', 'video/mov']
        // 限制格式
        const isUrl = is_video.includes(item.file.type)
        if (!isUrl) {
          self.$errMsg('上传视频只能是 mp4 ,mov 格式!');
        }
        if (isUrl) {
          self.loading = true
          let newFile = new FormData()
          newFile.append('file', item.file);
          self.$trainApi.uploadVideo(newFile).then(res => {
            self.loading = false
            if (res.code == 1000) {
              self.video_url = res.result.url
              self.video_url_short_url = res.result.short_url
            } else {
              self.$errMsg(res.message)
            }
          })
        }
      },
      // 确定
      affirmBtn(){
        var that = this
        if(that.video_url==''){
          that.$warMsg('请上传视频')
          return
        }else{
          // 编辑
          that.btnLoading=true
          var params = {
            id:that.id,
            training_video: that.video_url_short_url,
          }
          that.$trainApi.upVideo(params,that.id).then(res => {
            that.btnLoading=false
            if (res.code == 1000) {
              that.$succMsg(res.message)
              that.cancelBtn()
              that.$emit('submit')
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
    },
	}
</script>

<style lang='scss' scoped>
	.edit_info{
    .inv_main {
      width: 100%;
      padding: 0 20px 0 0;
      .exam_btn {
        position: relative;
        width: 360px;
        height: 200px;
        background: #F5F7FA;
        border-radius: 1px;
        border: 1px solid #DDDFE6;

        .btn_up {
          width: 100%;
          height: 100%;

          ::v-deep .el-upload {
            width: 360px;
            height: 200px;
          }

          .video_info {
            width: 100%;
            height: 100%;

            video {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }

        .video_btn {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          img {
            display: block;
            width: 25px;
            height: 25px;
          }

          span {
            display: block;
            padding-top: 5px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2E4BF2;
          }
        }

        .btn_txt {
          cursor: pointer;
          width: 100px;
          padding-top: 25px;

          .txt_btn_img {
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            ::v-deep .el-upload {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            img {
              display: block;
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }

            span {
              display: block;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2E4BF2;
            }
          }
        }
      }
    }
	}
</style>
