<template>
    <div class="model-edit zc_dialog_box">
        <el-dialog :visible.sync="dialogVisible" width="1100px" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">模特编辑</div>
            </div>
            <div class="inv_main">
                <div class="user_info">
                    <div class="info_title">模特信息</div>
                    <div class="info_txt">
                        <div class="txt">
                            <div class="t_left">
                                <span class="left_d">*</span>
                                <span class="left_t">模特名称</span>
                            </div>
                            <div class="t_right">
                                <div class="r_input">
                                    <el-input size="small" v-model="info.name"></el-input>
                                </div>
                            </div>
                        </div>
                        <div class="txt">
                            <div class="t_left">
                                <span class="left_d">*</span>
                                <span class="left_t">模型有效期</span>
                            </div>
                            <div class="t_right">
                                <div class="r_input">
                                    <el-date-picker v-model="info.expiry_date" style="width:100%" size='small' type="date"
                                        placeholder="选择日期" value-format="yyyy-MM-dd">
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="txt">
                            <div class="t_left">
                                <span class="left_d">*</span>
                                <span class="left_t">模特封面</span>
                            </div>
                            <div class="t_right">
                                <el-upload class="r_img" v-loading='imgLoading' action="#"
                                    accept="image/jpg,image/jpeg,image/png,image/gif" :http-request="uploadimg"
                                    :show-file-list="false">
                                    <el-image v-if="info.thumbnail" style="width: 100%; height: 100%" :src="info.thumbnail"
                                        fit="cover"></el-image>
                                    <div class="r_img_no w100 h100" v-else>
                                        <i class="el-icon-plus"></i>
                                        <div>上传封面</div>
                                    </div>
                                </el-upload>
                            </div>
                        </div>
                        <div class="txt">
                            <div class="t_left">
                                <span class="left_d">*</span>
                                <span class="left_t">模特视频</span>
                            </div>
                            <div class="t_right">
                                <div class="exam_btn" v-loading='videoLoading'>
                                    <div class="btn_up" v-if="info.training_video">
                                        <div class="video_info">
                                            <video :src="info.training_video" controls></video>
                                        </div>
                                    </div>
                                    <div class="btn_up" v-else>
                                        <el-upload action="#" :show-file-list="false" accept="video/mp4,video/mov" :http-request="uploadvideo">
                                            <div class="video_btn">
                                                <img src="@/assets/images/icon/upload.png" alt="">
                                                <span>上传视频</span>
                                            </div>
                                        </el-upload>
                                    </div>
                                    <div class="btn_txt">
                                        <div class="txt_btn_img">
                                            <el-upload action="#" :show-file-list="false" accept="video/mp4,video/mov" :http-request="uploadvideo">
                                                <img src="@/assets/images/icon/upload.png" alt="">
                                                <span>重新上传</span>
                                            </el-upload>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="txt">
                            <div class="t_left" style="padding-left: 13px;">
                                <span class="left_d">*</span>
                                <span class="left_t">演示视频</span>
                            </div>
                            <div class="t_right">
                                <div class="exam_btn" v-loading='videoLoading2'>
                                    <div class="btn_up" v-if="info.demo_video">
                                        <div class="video_info">
                                            <video :src="info.demo_video" controls></video>
                                        </div>
                                    </div>
                                    <div class="btn_up" v-else>
                                        <el-upload action="#" :show-file-list="false" accept="video/mp4,video/mov" :http-request="uploadvideo2">
                                            <div class="video_btn">
                                                <img src="@/assets/images/icon/upload.png" alt="">
                                                <span>上传视频</span>
                                            </div>
                                        </el-upload>
                                    </div>
                                    <div class="btn_txt">
                                        <div class="txt_btn_img">
                                            <el-upload action="#" :show-file-list="false" accept="video/mp4,video/mov" :http-request="uploadvideo2">
                                                <img src="@/assets/images/icon/upload.png" alt="">
                                                <span>重新上传</span>
                                            </el-upload>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn" :loading="subLoading">确
                    定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
    },
    data() {
        return {
            dialogVisible: false,
            subLoading: false,
            info: {},
            videoLoading: false,
            videoLoading2: false,
            imgLoading: false,
        }
    },
    methods: {
        // 打开弹框
        openDialogBtn(id) {
            this.dialogVisible = true
            this.getDetails(id)
        },

        // 取消
        cancelBtn() {
            this.dialogVisible = false
        },
        // 确定
        affirmBtn() {
            if (this.info.name == '') {
                this.$warMsg('请输入模特名称')
            } else if (this.info.expiry_date == '' || !this.info.expiry_date) {
                this.$warMsg('请选择模特有效期')
            } else if (this.info.thumbnail == '') {
                this.$warMsg('请上传封面图')
            } else if (this.info.training_video == '') {
                this.$warMsg('请上传模特视频')
            } else if (this.info.demo_video == '') {
                this.$warMsg('请上传演示视频')
            } else {
                const data = {
                    id: this.info.id,
                    name: this.info.name,
                    expiry_date: this.info.expiry_date,
                    thumbnail: this.info.thumbnail,
                    training_video: this.info.training_video,
                    demo_video: this.info.demo_video,
                }
                this.subLoading = true
                this.$trainApi.drillFulfill(data).then(res => {
                    this.subLoading = false
                    if (res.code == 1000) {
                        this.$succMsg(res.message)
                        this.cancelBtn()
                        this.$emit('submit')
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            }

        },
        getDetails(id) {
            this.$trainApi.modelListDetails(id).then(res => {
                if (res.code == 1000) {
                    this.info = res.result
                }

            })
        },
        // 上传订单图片
        uploadimg(item) {
            var self = this
            var is_img = ['image/gif', 'image/png', 'image/jpg', 'image/jpeg']
            // 限制图片格式
            const isJPG = is_img.includes(item.file.type)
            // 限制图片大小
            const isLt2M = item.file.size / 1024 / 1024 <= 3;
            if (!isJPG) {
                self.$errMsg('上传图片只能是 JPG ,JPEG, PNG 格式!');
            }
            if (!isLt2M) {
                self.$errMsg('上传图片大小不能超过 2MB!');
            }
            if (isJPG && isLt2M) {
                self.imgLoading = true
                let newFile = new FormData()
                newFile.append('type', 1);
                newFile.append('file', item.file);
                self.$trainApi.uploadVideo(newFile).then(res => {
                    self.imgLoading = false
                    if (res.code == 1000) {
                        self.info.thumbnail = res.result.url
                    } else {
                        self.$errMsg(res.message)
                    }
                })
            }
        },
        // 上传训练视频
        uploadvideo(item) {
            var self = this
            var is_video = ['video/mp4', 'video/mov']
            // 限制格式
            const isUrl = is_video.includes(item.file.type)
            if (!isUrl) {
                self.$errMsg('上传视频只能是 mp4 ,mov 格式!');
            }
            if (isUrl) {
                self.videoLoading = true
                let newFile = new FormData()
                newFile.append('type', 1);
                newFile.append('file', item.file);
                self.$trainApi.uploadVideo(newFile).then(res => {
                    self.videoLoading = false
                    if (res.code == 1000) {
                        self.info.training_video = res.result.url
                    } else {
                        self.$errMsg(res.message)
                    }
                })
            }
        },
        // 上传演示视频
        uploadvideo2(item) {
            var self = this
            var is_video = ['video/mp4', 'video/mov']
            // 限制格式
            const isUrl = is_video.includes(item.file.type)
            if (!isUrl) {
                self.$errMsg('上传视频只能是 mp4 ,mov 格式!');
            }
            if (isUrl) {
                self.videoLoading2 = true
                let newFile = new FormData()
                newFile.append('file', item.file);
                self.$trainApi.uploadVideo(newFile).then(res => {
                    self.videoLoading2 = false
                    if (res.code == 1000) {
                        self.info.demo_video = res.result.url
                    } else {
                        self.$errMsg(res.message)
                    }
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.model-edit {
    .inv_main {
        width: 100%;

        .user_info {
            width: 100%;
            padding: 0 20px 20px;
            background: #F7F7F7;

            .info_title {
                width: 100%;
                line-height: 50px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                border-bottom: 1px solid #E7E7E7;

                span {
                    padding-left: 10px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #F2912E;
                }
            }

            .info_txt {
                width: 100%;
                padding-top: 5px;
                display: flex;
                flex-wrap: wrap;

                .txt {
                    width: 45%;
                    padding-top: 20px;
                    display: flex;

                    .t_left {
                        width: 100px;
                        height: 32px;
                        display: flex;
                        align-items: center;

                        .left_d {
                            display: block;
                            width: 10px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FF2B2B;
                        }

                        .left_t {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                        }

                        img {
                            display: block;
                            width: 14px;
                            height: 14px;
                            margin-left: 5px;
                            margin-top: 2px;
                        }
                    }

                    .t_right {
                        display: flex;

                        .r_txt {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }

                        .r_input {
                            width: 210px;

                            .el-input {
                                width: 100%;
                            }

                            .el-select {
                                width: 100%;
                            }

                            ::v-deep .el-input-group__append {
                                padding: 0 10px;
                                color: #fff;
                                background: #2E4BF2;
                            }
                        }

                        .r_checkbox {
                            padding-left: 5px;
                            display: flex;

                            .el-checkbox {
                                padding-top: 7px;
                                margin-right: 10px;
                            }

                            .el-checkbox:last-child {
                                margin-right: 0;
                            }
                        }

                        .r_upimg {
                            width: 40px;
                            height: 80px;
                            margin-top: 10px;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .r_btn {
                            cursor: pointer;
                            line-height: 32px;
                            margin-left: 20px;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #2E4BF2;
                        }

                        .img_url {
                            margin-top: 7px;
                            width: 226px;
                            height: 131px;
                            border-radius: 1px;
                            border: 1px solid #DDDFE6;

                            video {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .r_img {
                            width: 148px;
                            height: 148px;
                            cursor: pointer;
                            border: 1px solid #e8eaec;
                            background-color: #f5f7fa;
                            border-radius: 5px;

                            .r_img_no {
                                padding-top: 50px;
                                font-size: 14px;
                                color: #2E4BF2;

                                i {
                                    font-size: 28px;
                                }

                                div {
                                    padding-top: 5px;
                                }
                            }
                        }

                        .exam_btn {
                            position: relative;
                            width: 255px;
                            height: 148px;
                            background: #F5F7FA;
                            // border-radius: 1px;
                            border: 1px solid #DDDFE6;

                            .btn_up {
                                width: 100%;
                                height: 100%;

                                ::v-deep .el-upload {
                                    width: 255px;
                                    height: 148px;
                                }

                                .video_info {

                                    width: 100%;
                                    height: 100%;
                                    video {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }

                            .video_btn {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;

                                img {
                                    display: block;
                                    width: 25px;
                                    height: 25px;
                                }

                                span {
                                    display: block;
                                    padding-top: 5px;
                                    font-size: 14px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #2E4BF2;
                                }
                            }

                            .btn_txt {
                                cursor: pointer;
                                position: absolute;
                                bottom: 0;
                                right: -100px;

                                .txt_btn_img {
                                    width: 100px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    ::v-deep .el-upload {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }

                                    img {
                                        display: block;
                                        width: 20px;
                                        height: 20px;
                                        margin-right: 5px;
                                    }

                                    span {
                                        display: block;
                                        font-size: 14px;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #2E4BF2;
                                    }
                                }
                            }
                        }
                    }
                }

                .txt:nth-child(2n) {
                    width: 55%;
                }

                .info_two {
                    width: 55%;
                    height: 80px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    .info_txt {
                        width: 100%;
                        line-height: 40px;
                        display: flex;

                        .t_left {
                            width: 100px;
                            padding-left: 10px;
                        }

                        .t_right {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }

                        .t_mp {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #2E4BF2;

                            img {
                                cursor: pointer;
                                display: block;
                                width: 15px;
                                height: 15px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }


        }
    }
}

::v-deep .el-upload {
    width: 100%;
    height: 100%;
}
</style>