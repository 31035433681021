<!-- @Author: Yu_Bo -->
<template>
	<div class='video_effect zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="690px" :close-on-click-modal='false' @close='cancelBtn'>
      <div slot="title">
        <div class="name">训练效果</div>
      </div>
      <div class="video_main">
        <div class="main_con">
          <video ref="videoPlayer" :src="videoUrl"  controls="controls" autoplay="autoplay" loop="loop"></video>
        </div>
        <div class="main_right">
          <div class="right_img"></div>
          <div class="right_img"></div>
          <div class="right_img"></div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btnBgColor_blue_empty_no_grey" size="small">下载视频</el-button>
        <el-button class="btnBgColor_blue" size="small" type="primary">复制视频链接</el-button>
        <el-button class="btnBgColor_blue" size="small" type="primary">合成视频</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        dialogVisible:false,
        videoUrl:''
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(val) {
        this.videoUrl=val
        this.dialogVisible = true
      },
      // 取消
      cancelBtn() {
        this.dialogVisible = false
        this.$refs.videoPlayer.pause();//暂停
        // this.$refs.videoPlayer.play();//播放
      },
    },
	}
</script>

<style lang='scss' scoped>
	.video_effect{
    .video_main{
      width: 100%;
      padding: 0 45px 10px 45px;
      display: flex;
      justify-content: space-between;
      .main_con{
        width: 280px;
        height: 480px;
        video{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .main_right{
        width: 260px;
        height: 480px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .right_img{
          cursor: pointer;
          width: 260px;
          height: 150px;
          background: #262626;
        }
      }
    }
	}
</style>
