<!-- @Author: Yu_Bo -->
<template>
	<div class='reject zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="624px" :close-on-click-modal='false' @close='cancelBtn'>
      <div slot="title">
        <div class="name">驳回原因</div>
      </div>
      <div class="inv_main">
        <el-input
          type="textarea"
          :rows="8"
          resize="none"
          placeholder="请输入驳回原因"
          v-model="textarea"
          maxlength="100"
          show-word-limit>
        </el-input>
      </div>
      <div slot="footer">
        <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
        <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">确 定</el-button>
      </div>
    </el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        id:'',
        textarea:'',
        btnLoading:false,
        //
        dialogVisible:false,
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(val) {
        this.id=val
        this.dialogVisible = true
      },
      // 取消
      cancelBtn() {
        this.dialogVisible = false
      },
      // 确定
      affirmBtn(){
        var that = this
        if(that.textarea==''){
          that.$errMsg('请输入驳回原因')
          return
        }else{
          that.btnLoading=true
          var params = {
            id:that.id,
            status:2,
            remark:that.textarea,
          }
          that.$trainApi.modelAudit(params).then(res => {
            that.btnLoading=false
            if (res.code == 1000) {
              that.$succMsg(res.message)
              that.cancelBtn()
              that.$emit('submit')
            } else {
              that.$errMsg(res.message)
            }
          })
        }
      },
    },
	}
</script>

<style lang='scss' scoped>
	.reject{
    .inv_main {
      width: 100%;
    }
	}
</style>
