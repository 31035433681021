<!-- @Author: Yu_Bo -->
<template>
	<div class='screen_model'>
    <div class="left">
      <el-button size="small" class="btnBgColor_blue_empty_no" icon="el-icon-plus" @click="addBtn">新增模型训练</el-button>
      <div class="left_txt">
        <el-input size="small" v-model="name" @change="searchBtn" :clearable="true" placeholder="请输入客户姓名/手机"></el-input>
      </div>
      <div class="left_txt">
        <el-input size="small" v-model="project" @change="searchBtn" :clearable="true" placeholder="请输入所属项目"></el-input>
      </div>
      <div class="left_txt" style="width: 150px;">
        <el-select v-model="payment" size="small" placeholder="付款方式" @change="searchBtn" :clearable="true">
            <el-option key="" label="全部" value=""></el-option>
            <el-option v-for="item in pay_type" :key="item.value" :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
      </div>
      <div class="left_txt" style="width: 150px;">
        <el-select v-model="status" size="small" placeholder="选择审核状态" @change="searchBtn" :clearable="true">
            <el-option key="" label="全部" value=""></el-option>
            <el-option v-for="item in status_list" :key="item.value" :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
      </div>
      <div class="left_txt" style="width: 150px;">
        <el-select v-model="recharge" size="small" placeholder="选择充值状态" @change="searchBtn" :clearable="true">
            <el-option key="" label="全部" value=""></el-option>
            <el-option v-for="item in recharge_list" :key="item.value" :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
      </div>
      <div class="left_txt" style="width: 150px;">
        <el-select v-model="timeValue" size="small" @change="searchBtn" :clearable="true">
            <el-option v-for="item in time_list" :key="item.value" :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
      </div>
      <div class="left_txt" style="width: 300px;">
        <el-date-picker size='small' v-model="dateTime" type="daterange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
            @change="searchBtn" :clearable="true">
        </el-date-picker>
      </div>
    </div>
    <div class="right">
      <el-button size="small" icon="el-icon-upload2" @click="exportBtn">导出</el-button>
    </div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
      pay_type:{
        type:Array,
        defaul: ()=>[]
      },
      status_list:{
        type:Array,
        defaul: ()=>[]
      },
      recharge_list:{
        type:Array,
        defaul: ()=>[]
      }
    },
		data() {
			return{
        name:'',
        project:'',
        //
        payment:'',
        //
        status:'',
        //
        recharge:'',
        //
        timeValue:'create_time',
        time_list:[
          {value:'create_time',label:'创建时间段'},
          {value:'audit_time',label:'审核时间段'},
        ],
        dateTime:''
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 添加
      addBtn(){
        this.$emit('addSound')
      },
      // 搜索
      searchBtn(){
        var obj = {
          keyword:this.name,
          project_name:this.project,
          pay_type:this.payment,
          status:this.status,
          recharge_type:this.recharge,
          select_time:this.timeValue,
          time_quantum:this.dateTime?`${this.dateTime[0]}|${this.dateTime[1]}`:'',
        }
        this.$emit('search',obj)
      },
      // 导出
      exportBtn(){},
    },
	}
</script>

<style lang='scss' scoped>
	.screen_model{
    width: 100%;
    padding: 10px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .btnBgColor_blue_empty_no{
        margin-top: 10px;
      }
      .left_txt{
        width: 170px;
        margin-left: 20px;
        margin-top: 10px;
        .el-date-editor{
          width: 300px;
        }
      }
    }
    .right{
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
	}
</style>
