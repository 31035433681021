<!-- @Author: Yu_Bo -->
<template>
  <div class='train_model'>
    <!-- 标题 -->
    <div class="vue_title">
      模型训练
    </div>
    <!--  -->
    <div class="model_data">
      <!-- 筛选 -->
      <screen-model :pay_type='pay_type' :status_list='status' :recharge_list='recharge_type' @addSound='addSoundBtn'
        @search='searchBtn'>
      </screen-model>
      <!-- 列表 -->
      <div class="main_table">
        <el-table :data="list" v-loading="loading" style="width: 100%" ref="multipleTable"
          @selection-change="handleSelectionChange" header-cell-class-name="table-header" cell-class-name="cell-class">
          <template slot="empty">
            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
          </template>
          <el-table-column type="selection" align="center" width="55"></el-table-column>
          <el-table-column prop="id" label="序号" width="80"></el-table-column>
          <el-table-column prop="name" label="模特名称" width="100"></el-table-column>
          <el-table-column label="训练视频" width="120">
            <template slot-scope="scope">
              <div class="tab_span_video">
                <video :src="scope.row.training_video"></video>
                <!-- <el-image style="width: 100%; height: 100%" src="" fit="cover"></el-image> -->
                <div class="video_icon" @click="previewBtn(scope.row.training_video)"><img
                    src="@/assets/images/icon/play_icon.png" alt=""></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="客户信息" width="180">
            <template slot-scope="scope">
              <div class="tab_span_user">
                <div class="span_name">
                  <span class="name one-txt-cut">{{ scope.row.corp ? scope.row.corp.name : scope.row.member.nickname }}</span>
                  <span class="name_color2" v-if="scope.row.corp">企业</span>
                  <span class="name_color1" v-else>个人</span>
                </div>
                <div class="span_txt">{{ scope.row.phone }}</div>
                <div class="span_txt">
                  <span>{{ scope.row.corp ? scope.row.corp.video_duration_text : scope.row.member.video_duration_text }}</span>
                </div>
                <div class="span_txt">
                  <span>{{ scope.row.corp ? (scope.row.corp.major_cust || 0) : (scope.row.member.major_cust || 0) }}次</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="project_name" label="所属项目"></el-table-column>
          <el-table-column prop="order_money" label="订单金额"></el-table-column>
          <el-table-column label="付款方式">
            <template slot-scope="scope">
              <div class="tab_span" v-if="scope.row.pay_type == 1">钻石</div>
              <div class="tab_span" v-if="scope.row.pay_type == 2">兑换码</div>
              <div class="tab_span" v-if="scope.row.pay_type == 3">次数</div>
            </template>
          </el-table-column>
          <el-table-column prop="video_duration" label="充值时长"></el-table-column>
          <el-table-column label="充值状态">
            <template slot-scope="scope">
              <div class="tab_span">
                <span class="color2" v-if="scope.row.recharge_type == 1">未通知</span>
                <span class="color3" v-if="scope.row.recharge_type == 2">待充值</span>
                <span class="color1" v-if="scope.row.recharge_type == 3">已充值</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="创建人" width="160">
            <template slot-scope="scope">
              <div class="tab_span">
                <span>{{ scope.row.create_name }}</span>
                <span>{{ scope.row.create_mobile }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间" width="180"></el-table-column>
          <el-table-column label="训练状态" width="100">
            <template slot-scope="scope">
              <div class="tab_span">
                <span class="color3" v-if="scope.row.status == 1">{{ scope.row.status_text }}</span>
                <span class="color2" v-if="scope.row.status == 2">审核未通过</span>
                <span class="color5" v-if="scope.row.status == 3">训练中</span>
                <span class="color3" v-if="scope.row.status == 4">训练完成</span>
                <span class="color2" v-if="scope.row.status == 5">训练失败</span>
                <span class="color1" v-if="scope.row.status == 6">已上架</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="update_time" label="审核时间" width="180"></el-table-column>
          <el-table-column class-name="last_class" label="操作" fixed="right" width="150">
            <template slot-scope="scope">
              <div class="tab_btn">
                <div class="btn" v-if="scope.row.recharge_type == 1 && (scope.row.status == 4 || scope.row.status == 6)">
                  <el-button type="text" size="small" :loading="scope.row.subLoading" @click="noticeBtn(scope.row.id, scope.row)">通知充值</el-button>
                </div>
                <div class="btn" v-if="scope.row.status == 2 || scope.row.status == 5">
                  <el-button type="text" size="small" @click="uploadBtn(scope.row.id)">重新上传</el-button>
                </div>
                <div class="btn" v-if="scope.row.status == 1 && scope.row.status_text == '待审核'">
                  <el-button type="text" size="small" @click="examineBtn(scope.row.id)">审核</el-button>
                </div>
                <div class="btn" v-if="scope.row.status_text != '审核中' && scope.row.status != 6">
                  <el-button type="text" size="small" @click="editBtn(scope.row)">编辑</el-button>
                </div>
                <div class="btn" v-if="scope.row.status == 4">
                  <el-button type="text" size="small" @click="releaseBtn(scope.row)">发布</el-button>
                </div>
                <div class="btn" v-if="scope.row.status_text != '待审核'">
                  <el-button type="text" size="small" @click="detailsBtn(scope.row.id)">详情</el-button>
                </div>
                <div class="btn" v-if="scope.row.status == 4 || scope.row.status == 6">
                  <el-button type="text" size="small" @click="effectBtn(scope.row.training_video)">预览</el-button>
                </div>
                <div class="btn" v-if="scope.row.status != 1 && scope.row.status != 2">
                  <el-button type="text" size="small" @click="modelBtn(scope.row)">模特编辑</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="page_box" v-if="total" style="padding-bottom: 20px;">
        <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
          @currentChange='currentChangeBtn'></paging-page>
      </div>
    </div>
    <!-- 预览 -->
    <video-preview ref="video_preview"></video-preview>
    <!-- 训练效果 -->
    <video-effect ref="video_effect"></video-effect>
    <!-- 通知成功 -->
    <point-succ ref='dialogTipSucc' type='model' dialogTitle="通知成功" dialogWidth='380px' :iconShow='true'
      iconColor='#36CB00' borderRadius='10px' :cancelShow='false' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">已通知管理员进行充值，视频时长预计在24小时内到账，请耐心等待。</div>
      </div>
    </point-succ>
    <!-- 温馨提示 -->
    <point-out ref='dialogTip' dialogTitle="温馨提示" type="model" @close='closeListBtn' dialogWidth='400px' :iconShow='true'
      iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">是否确认将模型<span>{{ infoModel }}</span>绑定给客户{{ infoName }}</div>
      </div>
    </point-out>
    <!-- 编辑客户信息 -->
    <edit-info ref="edit_info" @submit='getList'></edit-info>
    <!-- 驳回原因 -->
    <video-reject ref="video_reject" @submit='rejectBtn'></video-reject>
    <!-- 审核 -->
    <to-examine ref="to_examine" @submit='getList' @refuse='refuseBtn' :platform_type='platform_type'></to-examine>
    <!-- 详情 -->
    <video-details ref="details" :platform_type='platform_type'></video-details>
    <!-- 新增 -->
    <add-model ref="add_model" @submit='getList' :levelList='member_leve' :dayList='expiry_date'
      :platform_type='platform_type'></add-model>
    <!-- 重新上传 -->
    <video-upload ref="upload" @submit='getList'></video-upload>
    <!-- 模特编辑 -->
    <model-edit ref="ModelEdit" @submit='getList'></model-edit>
  </div>
</template>

<script>
import ScreenModel from '../components/screen/model.vue'
import VideoPreview from '../components/video/preview.vue'//预览
import VideoEffect from '../components/video/effect.vue'//训练效果
import EditInfo from '../components/video/edit_info.vue'//编辑客户信息
import VideoReject from '../components/video/reject.vue'//驳回原因
import ToExamine from '../components/video/to_examine.vue'//审核
import VideoDetails from '../components/video/details.vue'//详情
import AddModel from '../components/video/add_model.vue'//新增
import VideoUpload from '../components/video/video_upload.vue'//重新上传
import ModelEdit from '../components/model/edit.vue'//模特编辑
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import PointSucc from '@/components/point_out/point_succ.vue'//通知成功
import PointOut from '@/components/point_out/point_out.vue'//温馨提示
export default {
  components: {
    ScreenModel,
    VideoPreview,
    VideoEffect,
    EditInfo,
    VideoReject,
    ToExamine,
    VideoDetails,
    AddModel,
    VideoUpload,
    ModelEdit,
    EmptyData,
    PagingPage,
    PointSucc,
    PointOut,
  },
  props: {},
  data() {
    return {
      // 筛选
      pay_type: [],//付款状态
      status: [],//审核状态
      recharge_type: [],//充值状态
      tj_status: [],//提交人审核状态
      platform_type: [],//部署平台
      member_leve: [],//等级
      expiry_date: [],//有效期
      //
      list: [],
      sub: false,
      multipleSelection: [],
      //
      params: {},
      page: 1,
      limit: 10,
      total: 0,
      //
      infoId: '',
      infoModel: '',
      infoName: '',
      subLoading: false,
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getParams()
    this.getList('')
  },
  mounted() { },
  methods: {
    // 获取筛选
    getParams() {
      var that = this
      that.$trainApi.modelParams().then(res => {
        if (res.code == 1000) {
          that.pay_type = res.result.pay_type
          that.status = res.result.status
          that.recharge_type = res.result.recharge_type
          that.tj_status = res.result.tj_status
          that.platform_type = res.result.platform_type
          that.platform_type.forEach(item => {
            item.show = false
          })
          that.member_leve = res.result.member_leve
          that.expiry_date = res.result.expiry_date
        } else {
          this.$errMsg(res.message)
        }
      })
    },
    // 列表
    getList() {
      var that = this
      that.loading = true
      var data = {
        ...that.params,
        page: that.page,
        limit: that.limit,
      }
      that.$trainApi.modelList(data).then(res => {
        that.loading = false
        if (res.code == 1000) {
          that.list = res.result.data
          if (that.list && that.list.length) {

            that.list.forEach(item => {
              item.subLoading = false
            })
          }
          that.total = res.result.total
        } else {
          this.$errMsg(res.message)
        }
      })
    },
    searchBtn(params) {
      this.params = params
      this.page = 1
      this.getList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 改变每页条数
    sizeChangeBtn(val) {
      this.limit = val
      this.page = 1
      this.getList()
    },
    // 改变页数
    currentChangeBtn(val) {
      this.page = val
      this.getList()
    },
    // 预览
    previewBtn(url) {
      this.$refs.video_preview.openDialogBtn(url)
    },
    // 训练效果
    effectBtn(url) {
      this.$refs.video_effect.openDialogBtn(url)
    },
    // 通知
    noticeBtn(vid, item) {
      var params = {
        id: vid
      }
      item.subLoading = true
      this.$trainApi.callRecharge(params).then(res => {
        item.subLoading = false
        if (res.code == 1000) {
          this.getList()

          this.$refs.dialogTipSucc.openDialogBtn(vid)
        } else {
          this.$errMsg(res.message)
        }
      })
    },
    // 发布
    releaseBtn(info) {
      this.infoId = info.id
      this.infoModel = info.name
      this.infoName = info.nickname
      this.$refs.dialogTip.openDialogBtn()
    },
    closeListBtn() {
      var that = this
      var params = {
        id: that.infoId
      }
      that.$trainApi.modelShelf(params).then(res => {
        if (res.code == 1000) {
          that.$succMsg(res.message)
          that.getList()
        } else {
          this.$errMsg(res.message)
        }
      })
    },
    // 编辑
    editBtn(val) {
      this.$refs.edit_info.openDialogBtn(val)
    },
    // 重新上传
    uploadBtn(val) {
      this.$refs.upload.openDialogBtn(val)
    },
    // 审核
    examineBtn(val) {
      this.$refs.to_examine.openDialogBtn(val)
    },
    // 详情
    detailsBtn(val) {
      this.$refs.details.openDialogBtn(val)
    },
    // 新增
    addSoundBtn() {
      this.$refs.add_model.openDialogBtn()
    },
    // 拒绝
    refuseBtn(val) {
      this.$refs.video_reject.openDialogBtn(val)
    },
    rejectBtn() {
      this.getList()
      this.$refs.to_examine.cancelBtn()
    },
    // 模特编辑
    modelBtn(row) {
      this.$refs.ModelEdit.openDialogBtn(row.id)
    }
  },
}
</script>

<style lang='scss' scoped>
.train_model {
  width: 100%;

  .model_data {
    width: 100%;
    padding: 0 20px;
  }

  .tab_span {
    width: 100%;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;

    span {
      display: block;
      width: 100%;
      line-height: 25px;
    }

    .color1 {
      color: #36CB00;
    }

    .color2 {
      color: #FF0000;
    }

    .color3 {
      color: #FFA100;
    }

    .color5 {
      color: #999;
    }
  }

  .tab_span_user {
    width: 100%;

    .span_name {
      width: 100%;
      line-height: 25px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      display: flex;
      align-items: center;

      .name {
        max-width: 100px;
      }

      .name_color1 {
        display: block;
        margin-left: 10px;
        height: 18px;
        line-height: 18px;
        padding: 0 5px;
        color: #FF5000;
        font-size: 12px;
        background: rgba(255, 142, 72, 0.2);
        border-radius: 2px;
      }

      .name_color2 {
        display: block;
        margin-left: 10px;
        height: 18px;
        line-height: 18px;
        padding: 0 5px;
        color: #5200FF;
        font-size: 12px;
        background: rgba(176, 72, 255, 0.2);
        border-radius: 2px;
      }
    }

    .span_txt {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;

      span {
        color: #5200FF;
      }
    }
  }

  .tab_span_video {
    position: relative;
    width: 50px;
    height: 80px;
    margin-left: 5px;
    background: rgba(0, 0, 0, 0.3);

    video {
      display: block;
      width: 100%;
      height: 100%;
    }

    .video_icon {
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .tab_btn {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .btn {
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }
}</style>
